import axios from "axios";
import React, { useEffect, useState } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function Blogs() {
    const [blogList, setBlogList] = useState<any | null>({})
    const base_url = process.env.REACT_APP_BACKEND_URL;
    const Url = window.location.host
    const blogUrl = `https://blog.${Url}`;
  useEffect(() => {
    axios.get(`${base_url}/api/blog_list?`, {
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Methods": "GET, POST",
        },
    
    }).then(e => {
        setBlogList(e.data.data)
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
    })
  }, []);
  return (
    <section className='greview-section' id='news-section'>
        <div className="container">
            <div className="text-center aos-init m-4 mt-5" data-aos="zoom-in-up">
                <h2>Blogs & Events</h2>
            </div>
            <div className="row">
                <div className='col-md-12'>
                <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    className="home-slider"
                >
                    {blogList?.length > 0 &&
                        <SwiperSlide>                                
                            <div className='latestnews-card'>
                                <div className='latestnews-img'>
                                    <img src={blogList[0].grid_lg_img} alt="blogList" />
                                    <div className='latestnews-info'>
                                        <div className='latestnews-top'>
                                            {/* <span className='latestnews-tag'>{blogList[0].category_name}</span> */}
                                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                        </div>
                                        <div className='latestnews-bottom'>
                                            <div className='profile-user'>
                                                {/* <a className='profile-user-img'>
                                                    <img src={blogList[0].grid_lg_img}  className="rounded-circle" />
                                                </a> */}
                                                <div className='profile-user-info latestnews-user-info'>
                                                    {/* <h6><a target='_blank' href="">Orlando Waters</a></h6> */}
                                                    <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[0].formatted_date} {blogList[0].formatted_month} {blogList[0].formatted_year}</span>
                                                </div>
                                            </div>
                                            <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}>{blogList[0].page_title.substring(0,80)}{blogList[0].page_title.length > 80 ? '...' : ''}</a></h3>
                                            <p className='text-white'>{blogList[0].preview_text.substring(0,95)}{blogList[0].preview_text.length > 95 ? '...' : ''}</p>
                                            {/* <div className='latestnews-arlk-count'>
                                                <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </SwiperSlide>
                    }
                    {blogList?.length > 1 &&
                        <SwiperSlide>                                
                            <div className='latestnews-card'>
                                <div className='latestnews-img'>
                                    <img src={blogList[1].grid_lg_img} alt="blogList" />
                                    <div className='latestnews-info'>
                                        <div className='latestnews-top'>
                                            {/* <span className='latestnews-tag'>{blogList[1].category_name}</span> */}
                                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                        </div>
                                        <div className='latestnews-bottom'>
                                            <div className='profile-user'>
                                                {/* <a className='profile-user-img'>
                                                    <img src={blogList[1].grid_lg_img}  className="rounded-circle" />
                                                </a> */}
                                                <div className='profile-user-info latestnews-user-info'>
                                                    {/* <h6><a target='_blank' href="">Richard Carg</a></h6> */}
                                                    <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[1].formatted_date} {blogList[1].formatted_month} {blogList[1].formatted_year}</span>
                                                </div>
                                            </div>
                                            <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}>{blogList[1].page_title.substring(0,80)}{blogList[1].page_title.length > 80 ? '...' : ''}</a></h3>
                                            <p className='text-white'>{blogList[1].preview_text.substring(0,95)}{blogList[1].preview_text.length > 95 ? '...' : ''}</p>
                                            {/* <div className='latestnews-arlk-count'>
                                                <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </SwiperSlide>
                    }
                    {blogList?.length > 2 &&
                        <SwiperSlide>                                
                            <div className='latestnews-card'>
                                <div className='latestnews-img'>
                                    <img src={blogList[2].grid_lg_img} alt="blogList" />
                                    <div className='latestnews-info'>
                                        <div className='latestnews-top'>
                                            {/* <span className='latestnews-tag'>{blogList[2].category_name}</span> */}
                                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                        </div>
                                        <div className='latestnews-bottom'>
                                            <div className='profile-user'>
                                                {/* <a className='profile-user-img'>
                                                    <img src={blogList[2].grid_lg_img}  className="rounded-circle" />
                                                </a> */}
                                                <div className='profile-user-info latestnews-user-info'>
                                                    {/* <h6><a target='_blank' href="">Andrea Masadoni</a></h6> */}
                                                    <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[2].formatted_date} {blogList[2].formatted_month} {blogList[2].formatted_year}</span>
                                                </div>
                                            </div>
                                            <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}>{blogList[2].page_title.substring(0,80)}{blogList[2].page_title.length > 80 ? '...' : ''}</a></h3>
                                            <p className='text-white'>{blogList[2].preview_text.substring(0,95)}{blogList[2].preview_text.length > 95 ? '...' : ''}</p>
                                            {/* <div className='latestnews-arlk-count'>
                                                <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </SwiperSlide>
                    }
                </Swiper>
                </div>
            </div>
        </div>
    </section>
  );
}

export default Blogs;
