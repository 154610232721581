import React from 'react'
import  "./orderOnline.css"


function orderOnline() {
    return (
        <div className="wash-fold-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 d-none d-md-block"></div>
                        <div className="col-lg-8">
                            <div className="wash-fold-card">
                                <div className="wash-fold-text">
                                    <h2>WASH <br />& <br/> FOLD</h2>
                                </div>
                                <div className="wash-fold-info">
                                    <ul>
                                        <li><i className="fa fa-check"></i> <p>Expert <span className='theme-clr-primory'>laundering, </span>gentle <span className='theme-clr-primory'>drying.</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Medium heat is used to maintain <span className='theme-clr-primory'>garment quality.</span></p></li>
                                        <li><i className="fa fa-check"></i> <p>Washed below <span className='theme-clr-primory'>30 degrees for fabric care.</span></p></li>
                                        <li><i className="fa fa-check"></i><span><p>Assigned and monitored <span className='theme-clr-primory'>wash-and-fold orders.</span></p></span></li>
                                        <li><i className="fa fa-check"></i><p>Integrated weighing scales for <span className='theme-clr-primory'>speedy and accurate order processing.</span></p></li>
                                        <li><i className="fa fa-check"></i><p>Convenient <span className='theme-clr-primory'>pickup and delivery services.</span></p></li>
                                        <li><i className="fa fa-check"></i><p>Customer’s responsibility to <span className='theme-clr-primory'>verify washability of items.</span></p></li>
                                        <li><i className="fa fa-check"></i><p><span className='theme-clr-primory'>Towels and bedding </span>excluded and charged separately.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default orderOnline