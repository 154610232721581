import React, { useEffect, useState } from 'react'
import axios from 'axios'
import BookNow from '../../components/bookNow';
import WeWork from '../../components/weWork';
import PricingList from '../../components/pricingList';
import OrderOnline from '../../components/orderOnline';
import AboutBestAtLaundry from '../../components/aboutBestAtLaundry';
import LaundryService from '../../components/laundryService';
import BestOfferImage from '../../components/bestOfferImage';
import Offer from '../../components/offer';
import DownloadApp from '../../components/downloadApp';
import Howitworks from '../../components/howitWorks';
import AreaNotCovered from '../../components/areaNotCovered';
import Notification from '../../components/notification';
import GoogleReviews from '../../components/googleReview';
import Blogs from '../../components/blogs';
import ShopList from '../../components/shopList';
import HomeShopList from '../../components/homeShopList';
import { checkShopListFlow } from '../../utility';


function Home() {

  const base_url = process.env.REACT_APP_BACKEND_URL;
  const [getOffer, setGetOffer] = useState<any>();
  const [offerMessage, setOfferMessage] = useState<any>();

  useEffect(() => {
    const headers = {
      'Accept': 'application/json'
    }

    axios.get(`${base_url}/api/list_offer_details`, {
      headers: headers

    }).then(e => {
      setGetOffer({ result: e.data.data.offer_details })
      setOfferMessage(e.data.Response.response_message)
    }
    )
    document.title = 'Bestatlaundry | No1 Laundry&Dry Cleaning Service Provider in UK';
    document.getElementById('meta-title')?.setAttribute('content', 'Bestatlaundry | No1 Laundry&Dry Cleaning Service Provider in UK');
    document.getElementById('meta-description')?.setAttribute('content', "No1 laundry & dry cleaning service provider in the UK. We offer professional & best services for all your laundry needs. Experience convenience&quality today");

  }, [])


  


  return (
    <div>

      <BookNow />
      {checkShopListFlow() && <div className='home-shop'>
        <HomeShopList />
      </div>}
      <WeWork />
      <Howitworks />
      {/* <BestOfferImage /> */}
      <PricingList />
      {getOffer?.result?.length > 0 &&
        offerMessage !== "no offers available right now" &&
        <Offer />
      }
      <OrderOnline />
      <AboutBestAtLaundry />
      <LaundryService />
      <GoogleReviews />
      <Blogs />
      <DownloadApp />
      {/* <AreaNotCovered /> */}

    </div>
  )
}

export default Home