import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../aboutUs/aboutUs.css';
import { abouticon5,abouticon6,abouticon7,abouticon8,aboutimg1,aboutimg2 } from '../../assets/img';
import { fetchAboutUsData } from "../../redux/Actions/policyActions";

function AboutUs() {

    const dispatch = useDispatch<any>();

    const aboutUsData: any = useSelector<any>(
        (state) => state?.aboutUs
      );

    useEffect(() => {
        dispatch(fetchAboutUsData());
        document.title = 'Get Your Laundry Done Right, Every Time with BestatLaundry';
        document.getElementById('meta-title')?.setAttribute('content', 'Get Your Laundry Done Right, Every Time with BestatLaundry');
        document.getElementById('meta-description')?.setAttribute('content', "Get your laundry done right, every time with BestatLaundry. UK-based laundry and dry cleaning service provider. Professional, reliable, and affordable.");
      }, []);

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="breadcrumpview">
                            <h2>{aboutUsData?.title_en}</h2>
                            <ul>
                            <li>
                                <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                            </li>
                            <li>
                                <span>{aboutUsData?.title_en}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-content-wrapper about-content my-77 theme-clr-primory">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="policy-section">
                            <div dangerouslySetInnerHTML={{__html: aboutUsData?.details_en}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs