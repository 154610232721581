import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container-fluid">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="easytoimg" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h2 className="text-start">Simplify your laundry routine with our reliable and efficient dry cleaning services in London.</h2>
</div>
<div className="">
<p>Best at laundry - your premier choice for online <b>laundry and dry cleaning services</b> in London.</p>
<p>We offer a convenient <b>collection and delivery service</b> right to your doorstep.</p>
<p> We ensure excellence in each order that we receive.. We exclusively use <b>top-tier detergents and cleaning materials</b> to achieve outstanding results.</p>
<p>Our  <b>state-of-the-art machinery</b> combined with superior consumables establishes us as the <b>leading laundry service </b>in the area.
</p>
<p>
If you've ever been disappointed by stains that weren't fully removed, lingering odors, or garments that lacked vibrancy after using a budget cleaning service, you can
<b> trust </b>that our approach is different.
</p>
<p>
We firmly believe in the principle of <b>quality over compromise.</b> By utilizing only the <b>best resources</b>, we guarantee that your clothing will be returned to you <b>flawlessly clean </b>and in <b>perfect condition</b>
</p>
<p>
<b>“Don’t Wait; Reach Us and Get Effective Services.” Bestatlaundry is available all day to
help you. </b>
</p>
</div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;