import React from 'react'
import {aboutimg,abouticon1,abouticon2,abouticon3,abouticon4} from '../../assets/img'

function AboutBestAtLaundry() {
  
  return (
    <div>
      <section className="aboutsec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img src={aboutimg} className="img-fluid aos-init" alt="aboutimg" data-aos="zoom-in-up" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content-right aos-init" data-aos="zoom-in-up">
                <div className="section-title">
                  <h2 className="text-start mb-2">BestatLaundry</h2>
                  <h2 className='mb-1 section-title-head'>Where quality meets convenience - your trusted dry cleaning experts in London.</h2>
                  <div className="section-line">
                    <span className='first-line'></span>
                  </div>
                </div>

                <p className='mt-1 mb-1'><p className='mt-1 mb-2'>From the moment our laundry business emerged, our aspiration was crystal clear: to become the epitome of the laundry industry in London.</p>
                <p className='mt-1 mb-2'>Welcome to our exceptional laundry services!</p>
                <p className='mt-1 mb-2'>Our commitment to providing unmatched quality cleaning services sets us apart from the rest.</p>
                <p className='mt-1 mb-2'>With our innovative approach, we go beyond traditional laundry services to deliver a truly outstanding experience. Our professional staff is trained to handle all types of fabrics and garments, utilizing state-of-the-art equipment and techniques to ensure the best possible results.</p>
                <p className='mt-1 mb-2'>At Bestatlaundry every step of the process is handled with precision and care.</p>
                <p className='mt-1 mb-2'>Whether you're a business in need of regular laundry services or a homeowner looking for a reliable and convenient solution, we offer customizable packages to suit your specific needs.
                Trust us to provide the best laundry service in town, because your satisfaction is our top priority.</p></p>
                <div className="about-icon mt-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon1} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Cost Effective</h5>
                          <p>The Bestatlaundry provides the laundry services with affordable cost to all when compared it on market.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon2} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Ease to Use</h5>
                          <p>Simple and ease to getting our services and enjoy.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon3} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Fast Delivery</h5>
                          <p>Our team will collect and deliver your cloth items quickly with free of charge.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon4} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Quality of Service</h5>
                          <p>Our team will perfectly wash, dry clean, iron, fold, pack and return to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
    </div>
  )
}

export default AboutBestAtLaundry