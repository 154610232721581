import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { howstep1,howstep2,howstep3} from '../../assets/img'
import QuickOrderPopup from "../quickOrderPopup";
function Howitworks() {
    const [quickBookPopup, setQuickBookPopup] = useState(false);
  return (
    <div className="how-work-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h2 className="mb-2">Quick Order</h2>
                        <div className="sub-heading">How it works ?</div>
                    </div>
                </div>
            </div>
            <div className="how-work-inner aos-init" data-aos="zoom-in-up">
                
                <div className="how-work-wrapper">
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 1</span>
                                <img src={howstep1} alt="Laundry Pickup" />                                
                            </div>
                            <div className="work-content">
                                <p>Choose <strong>when</strong> and <strong>where</strong> you wish us to collect &amp; deliver your laundry.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 2</span>
                                <img src={howstep2} alt="Laundry Collection" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>collect</strong> your bag, <strong>list</strong> and <strong>clean</strong> your items according to <strong>your requirements</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 3</span>
                                <img src={howstep3} alt="Laundry Delivery" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>deliver</strong> your items cleaned within <strong>24 hours</strong> and at the time required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center" onClick={() => setQuickBookPopup(!quickBookPopup)}>
                        <Link to="#" className="btn howitbtn hover-btn" data-bs-toggle="modal" data-bs-target="#quickordermodal2">Order now</Link>
                    </div>
                    <div className="modal fade" id="quickordermodal2" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">

                            {quickBookPopup && (
                            <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            // <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            )}

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Howitworks;
