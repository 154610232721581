import React,{ useEffect,useState  } from 'react'
import { render } from 'react-dom'
import './../thankAppDownload/thankApp.css';
//const QuickOrderPopup :React.FC<{ close: any }> = ({ close }) => {
const FailedAppDownload :React.FC<{ close: any }> = ({ close })=>{
    const handleFailedAppDownloadPopupClose =() =>{
        close();
    }


    return(
<div className="custom-modal-class modal in" id="app_download_success_popup">
<div className="vertical-alignment-helper model-std">
<div className="modal-dialog modal-md modal-dialog-centered">
<div className="modal-content">

<div className="modal-body">
    <div className="alert-icons">
    <i className="fa fa-exclamation-circle" aria-hidden="true" />
    </div>
<h3>Problem in sending SMS. Please check your mobile number or try again after some time.</h3>
<input type="submit" value="Close" data-bs-dismiss="modal"  onClick={handleFailedAppDownloadPopupClose} />

</div>
</div>
</div>
</div>
</div>
)


}

export default FailedAppDownload;