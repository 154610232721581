import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { shopimg } from "../../assets/img";
import { fetchShopList } from "../../redux/Actions/checkoutPageActions";
import { fetchProductAllCategories } from "../../redux/Actions/productCategoriesAction";
import { NavLink } from 'react-router-dom';

function HomeShopList() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const settings: any = useSelector<any>((state) => state.settings);
  const shopsList: any = useSelector<any>(
    (state) => state.shopsList?.data
  );
  const [showAllServices, setShowAllServices] = useState(false);
  // const postalCode = JSON.parse(localStorage.getItem("postalCode") || "");

  useEffect(() => {
    const postalCodeValue = JSON.parse(
      localStorage.getItem("postalCode") || ""
    );
    dispatch(fetchShopList(postalCodeValue));
  }, []);

  const handleShopClick = (shopId: any) => {
    localStorage.setItem("shop_id", shopId);
    navigate("/productListsDetails");
  };

  return (
    <>
      {/* <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Local Businesses</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>{postalCode}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12">
            <div className="home-shop-hdr">
              <h2 className="d-inline-block">Popular shops in UK</h2>
              {/* <NavLink className="ms-2" to={'./shopList'}>View All <i className="fas fa-angle-right ms-2"></i></NavLink> */}
            </div>
          </div>
        </div>
        <div className="row">
          {shopsList?.shops.slice(0, 4).map((item: any) => {
            return (
              <>
                <div className="col-md-3 d-flex">
                  <div className="shop-grid">
                    <div className="shop-img text-center">
                      {item?.setting?.shop_logo_url ? <img className="p-3" src={item?.setting?.shop_logo_url || shopimg} alt="" /> : <img src={shopimg} alt="" />}
                      {/* <img src={item?.setting?.shop_logo_url || shopimg} alt="" /> */}
                    </div>
                    <div className="shop-details">
                      <h3
                        onClick={() => handleShopClick(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </h3>
                      <p className="clr-grey shop-details-adrs">
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {item.address.text_address}
                      </p>
                      <ul className="d-none">
                        {item.recent_products.map((services: any) => {
                          return (
                            <>
                              <li>
                                <div>
                                  {services.groupcategory.subcategory.name}
                                  <span className="clr-grey ms-4">
                                    <i className="far fa-clock me-2"></i>
                                    {services.groupcategory.process_time}hrs
                                  </span>
                                </div>
                                <div className="shop-price">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    services.group_category_default_option_price
                                      .price
                                  }
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="shop-serv-link">
                        <a onClick={() => handleShopClick(item.id)}
                        style={{ cursor: "pointer" }}>See all services</a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default HomeShopList;
